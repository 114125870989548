import React from "react";
import { Layout, Menu, Icon, Dropdown, Avatar, Tag, Tooltip } from "antd";
import { Link } from "react-router-dom";
import "./index.scss";
import logo from "../../../../assets/images/logos/logohead.svg";
import { ReactComponent as InfoCircle } from "../../../../assets/images/icons/info-cirlce-o.svg";

const { Header } = Layout;
const { Item } = Menu;

const Navbar = props => {
  const { user, domain, plan } = props;
  const { user_fname } = user;
  let showBonuses = false;
  if (
    domain.role === "owner" &&
    (user.origin === "JVZOO" || user.origin === "THRIVECART")
  ) {
    showBonuses = true;
  }

  const userDropdownMenu = (
    <Menu className="navbar-user-dropdown">
      <Item key="account" onClick={() => props.toggleSwitchAccountModal()}>
        <div className="current-account-details">
          <span className="account-name">
            {domain && domain.dom_name
              ? domain.dom_name
              : user_fname.split(" ")[0] + "'s Domain"}
          </span>
          <Tag color="#FF8200">{user.user_domains[domain.id]["role"]}</Tag>
        </div>
        <p className="switch-account-label">
          <b>Switch Account</b>
        </p>
      </Item>
      <Item key="plan">
        <Tooltip title={props.plan.plan_name.join(" + ")}>
          Plan: {props.plan.plan_name.join(" + ")}
        </Tooltip>
      </Item>
      <Item key="profile_settings">
        <Link to="/settings">
          Profile Settings <Icon type="user" />
        </Link>
      </Item>
      {showBonuses && (
        <Item key="bonuses">
          <Link to="/bonus">
            Bonuses <Icon type="gift" />
          </Link>
        </Item>
      )}
      {/* <Item key="billing"><Link to={`/billing`}>Billing <Icon type="dollar" /></Link></Item>
            <Item key="facebook_group"><a href="https://www.facebook.com/groups/conversionhackersclub" target="_blank" rel="noreferrer noopener">Facebook Group <Icon type="facebook" /></a></Item> */}
      {domain.role === "owner" &&
        (props.loginplan.plan_features["DOM_COUNT"] === -1 ||
          props.loginplan.plan_features["DOM_COUNT"] > 1) && (
          <Item key="add_account">
            <Link to="/onboarding">
              Add Account <Icon type="usergroup-add" />
            </Link>
          </Item>
        )}
      <Item key="manage_accounts">
        <Link to="/manage-accounts">
          Manage Accounts <Icon type="usergroup-add" />
        </Link>
      </Item>
      {props.loginplan.plan_features["MAIL_MASTERY_LIVE"] === 1 && (
        <Item key="mail_mastery_sessions">
          <Link to="/mail-mastery-sessions">
            Mail Mastery Sessions <Icon type="usergroup-add" />
          </Link>
        </Item>
      )}
      {user.origin && user.origin === "PG" && (
        <Item key="activation_codes">
          <Link to="/activation-codes">
            Activation Codes <Icon type="key" />
          </Link>
        </Item>
      )}
      <Item key="signouy">
        <Link to="/logout">
          Sign Out <Icon type="poweroff" />
        </Link>
      </Item>
    </Menu>
  );

  return (
    <Header className="navbar">
      <div className="logo">
        <Link to={`/${domain.id}`}>
          <img src={logo} alt="logo-head" />
        </Link>
      </div>

      <Menu className="left-menu" theme="dark" mode="horizontal">
        <Item key={1}>
          <Link to={`/${domain.id}`}>Snapshot</Link>
        </Item>
        <Item key={2}>
          <Dropdown
            placement="bottomCenter"
            overlay={
              <Menu className="navbar-user-dropdown">
                <Item key={21}>
                  <Link to={`/${domain.id}/elements`}>Active Elements</Link>
                </Item>
                <Item key={22}>
                  <Link to={`/${domain.id}/elements/archived`}>
                    Archived Elements
                  </Link>
                </Item>
              </Menu>
            }
            trigger={["hover"]}
          >
            <Link className="ant-dropdown-link" to={`/${domain.id}/elements`}>
              Elements
            </Link>
          </Dropdown>
        </Item>
        <Item key={3}>
          <Link to={`/${domain.id}/my_links`}>My Links</Link>
        </Item>
        <Item key={4}>
          <Dropdown
            placement="bottomCenter"
            overlay={
              <Menu className="navbar-user-dropdown">
                <Item key={41}>
                  <Link to={`/${domain.id}/library`}>Your Library</Link>
                </Item>
                <Item key={42}>
                  <Link to={`/${domain.id}/library/youzign`}>
                    YouZign Library
                  </Link>
                </Item>
                <Item key={43}>
                  <Link to={`/${domain.id}/library/pixabay`}>
                    Pixabay Library
                  </Link>
                </Item>
                <Item key={44}>
                  <Link to={`/${domain.id}/library/search-gifs`}>
                    Tenor GIF Search
                  </Link>
                </Item>
              </Menu>
            }
            trigger={["hover"]}
          >
            <Link className="ant-dropdown-link" to={`/${domain.id}/library`}>
              Library
            </Link>
          </Dropdown>
        </Item>
        {plan.plan_features &&
          (plan.plan_features["AVIARY_EDITOR"] !== undefined ||
            plan.plan_features["EMAIL_BUILDER"] === 1) && (
            <Item key={6}>
              <Dropdown
                placement="bottomCenter"
                overlay={
                  <Menu className="navbar-user-dropdown">
                    {plan.plan_features["AVIARY_EDITOR"] !== undefined && (
                      <Item key={61}>
                        <a
                          href={`/user/tools_editor/${domain.id}`}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          Image Editor
                        </a>
                      </Item>
                    )}
                    {plan.plan_features["EMAIL_BUILDER"] === 1 && (
                      <Item key={61}>
                        <a
                          href={`/user/tools_email_builder/${domain.id}`}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          Email Builder
                        </a>
                      </Item>
                    )}
                  </Menu>
                }
                trigger={["hover"]}
              >
                <a href="#!">Tools</a>
              </Dropdown>
            </Item>
          )}
        <Item key={5}>
          <Dropdown
            placement="bottomCenter"
            overlay={
              <Menu className="navbar-user-dropdown">
                <Item key={51}>
                  <Link to={`/${domain.id}/settings/accounts`}>
                    Account Settings
                  </Link>
                </Item>
                {domain.role === "owner" &&
                  (props.plan.plan_features.USER_COUNT === -1 ||
                    props.plan.plan_features.USER_COUNT > 0) && (
                    <Item key={52}>
                      <Link to={`/${domain.id}/settings/users`}>Users</Link>
                    </Item>
                  )}
                <Item key={53}>
                  <Link to={`/${domain.id}/settings/domains`}>
                    Custom Domain
                  </Link>
                </Item>
              </Menu>
            }
            trigger={["hover"]}
          >
            <Link
              className="ant-dropdown-link"
              to={`/${domain.id}/settings/accounts`}
            >
              Settings
            </Link>
          </Dropdown>
        </Item>
      </Menu>

      <Menu className="right-menu" theme="dark" mode="horizontal">
        <Item key={6}>
          <Dropdown
            placement="bottomCenter"
            overlay={
              <Menu className="navbar-user-dropdown">
                <Item key="help_ticket">
                  <a
                    href="https://help.LetMailbox.com/portal/newticket"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Submit Ticket
                  </a>
                </Item>
                <Item key="help_kb">
                  <a
                    href="https://help.LetMailbox.com/portal/kb"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Knowledge Base
                  </a>
                </Item>
              </Menu>
            }
            trigger={["hover"]}
          >
            <a
              className="ant-dropdown-link"
              href="#/"
              style={{ fontSize: "1.05rem" }}
            >
              <Icon component={InfoCircle} style={{ margin: "-5px 5px 0 0" }} />{" "}
              Help
            </a>
          </Dropdown>
        </Item>

        <Item key={7}>
          <Dropdown overlay={userDropdownMenu} trigger={["hover"]}>
            <a className="ant-dropdown-link" href="#/">
              <Avatar
                style={{ backgroundColor: "#FC4747", verticalAlign: "middle" }}
                size="large"
              >
                {user_fname[0]}
              </Avatar>

              <span className="avatar-details">
                {user_fname}
                <br />
                <span className="domain-name">
                  {domain && domain.dom_name
                    ? domain.dom_name
                    : user_fname.split(" ")[0] + "'s Domain"}
                </span>
              </span>
            </a>
          </Dropdown>
        </Item>
      </Menu>
    </Header>
  );
};

export default Navbar;
