import React, { Component } from 'react';
import { Card, Skeleton, Alert, Row, Col, Modal, message } from 'antd';
import { isEqual, debounce } from 'lodash';
import LibraryModal from '../Library/LibraryModal';
import ElementFormContainer from './Elements/ElementFormContainer';
import PersonalisedAutoplayVideoElement from '../../../assets/images/element_icons/personalised_autoplay.png';
import AutoplayVideoElement from '../../../assets/images/element_icons/video.png';
import YesNoElement from '../../../assets/images/element_icons/yes-no.png';
import FeedbackElement from '../../../assets/images/element_icons/feedback_category.png';
import PollsElement from '../../../assets/images/element_icons/polls.png';
import QNAElement from '../../../assets/images/element_icons/QandA.png';
import GroupEventElement from '../../../assets/images/element_icons/group_event.png';
import TimerElement from '../../../assets/images/element_icons/timers.png';
import HellobarTimerElement from '../../../assets/images/element_icons/hellobar_timer.png';
import PersonalisedImageElement from '../../../assets/images/element_icons/personalised_images.png';
import LinkPreviewElement from '../../../assets/images/element_icons/link_previews.png';
import CTAElement from '../../../assets/images/element_icons/cta.png';
import FooterWidgetElement from '../../../assets/images/element_icons/footer_widgets.png';
import SignatureElement from '../../../assets/images/element_icons/signature.png';
import SocialBadgesElement from '../../../assets/images/element_icons/social_badges.png';
import SocialShareElement from '../../../assets/images/element_icons/social_share.png';
import SelfDestructMessageElement from '../../../assets/images/element_icons/self_destructing.png';
import TwitterEmbedElement from '../../../assets/images/element_icons/twitter_embed.png';
import YoutubeEmbedElement from '../../../assets/images/element_icons/youtube_embed.png';
import VimeoEmbedElement from '../../../assets/images/element_icons/vimeo.png';
import MapEmbedElement from '../../../assets/images/element_icons/map_embed.png';
import InstagramEmbedElement from '../../../assets/images/element_icons/insta_embed.png';
import PinterestEmbedElement from '../../../assets/images/element_icons/pinterest_embed.png';
import TwitterTimelineElement from '../../../assets/images/element_icons/twitter_timeline.png';
import GIFEmbedElement from '../../../assets/images/element_icons/gif_embed.png';
import ImageEmbedElement from '../../../assets/images/element_icons/image_embed.png';
import { newElement, scrapeURL, scrapeTwitterURL, scrapePinterestURL } from '../../../helpers/ajax/elementsAjax';
import { connect } from 'react-redux';
import './index.scss';
const { confirm } = Modal;

class NewElement extends Component {

  state = {
    elementForm: {
      visible: false,
      type: "",
    },
    libraryModal: {
      visible: false,
      type: 'image',
      tab: 'upload',
      onChoose: (media) => { console.log(media) }
    },
    element: undefined,
    categories: [],
    wlbldoms: [],
    timezones: {},
    loading: true,
    error: false,
    activeTab: 'tab1',
    errorMsg: '',
  }

  toggleElementForm(element) {
    const featureMap = {
      "PersonalisedVideoElement": "PERSONALISED_VIDEO",
      "MessengerPersonalisedVideoElement": "MESSENGER_PERSONALISED_VIDEO",
      "AutoplayVideoElement": "AUTOPLAY_VIDEOS",
      "YesNoElement": "YES_NO_BAR",
      "FeedbackElement": "FEEDBACK_WIDGET",
      "PollsElement": "POLLS",
      "QNAElement": "QNA",
      "GroupEventElement": "GROUP_EVENT_POLL",
      "TimerElement": "TIMERS",
      "HellobarTimerElement": "HELLOBAR_TIMERS",
      "PersonalisedImageElement": "PERSONALISED_IMAGES",
      "MessengerPersonalisedImageElement": "MESSENGER_PERSONALISED_IMAGES",
      "LinkPreviewElement": "LINK_PREVIEW",
      "CTAElement": "CTA_BUTTONS",
      "FooterWidgetElement": "FOOTER_WIDGET",
      "SignatureElement": "SIGNATURES",
      "SocialBadgesElement": "SOCIAL_BADGES",
      "SocialShareElement": "SOCIAL_SHARE_BUTTONS",
      "SelfDestructMessageElement": "SELF_DESTRUCTING_MSG",
      "TwitterEmbedElement": "TWITTER_EMBED",
      "YoutubeEmbedElement": "YOUTUBE_EMBED",
      "VimeoEmbedElement": "VIMEO_EMBED",
      "MapEmbedElement": "GOOGLE_MAP_EMBED",
      "InstagramEmbedElement": "INSTAGRAM_EMBED",
      "PinterestEmbedElement": "PINTERES_EMBED",
      "TwitterTimelineElement": "TWITTER_EMBED",
      "GIFEmbedElement": "GIF_EMBED",
      "ImageEmbedElement": "IMAGE_EMBED",
    }
    const features = this.props.auth.plan && this.props.auth.plan.plan_features ? this.props.auth.plan.plan_features : {};
    if (features[featureMap[element]] !== 1) {
      message.error("Your plan does not allow you to create this element!");
      return false;
    }
    this.setState({
      elementForm: {
        type: element,
        visible: !this.state.elementForm.visible
      }
    });
  }

  toggleLibraryModal(type, onChoose = (media) => { console.log(media) }, tab = 'upload') {
    this.setState({
      libraryModal: {
        type: type ? type : 'image',
        visible: !this.state.libraryModal.visible,
        tab,
        onChoose,
      }
    });
  }

  dismissElementForm() {
    confirm({
      title: 'Are you sure?',
      content: 'You will lose any unsaved changes',
      onOk: () => {
        this.setState({
          element: undefined,
          elementForm: {
            visible: false,
            type: ""
          }
        })
      },
      onCancel: () => { },
    })
  }

  scrapeURLRequest(url, callback) {
    scrapeURL(url).then((res) => {
      if (callback) {
        if (res.data.image) {
          res.data.image = res.data.image.filter(function (url) {
            return !url.url.match(/https?:\/\/(www)?.facebook.com\/?.*/) && !url.url.match(/https?:\/\/.*doubleclick.net\/?.*/);
          })
        }
        if (res.data.image && res.data.image.length) {
          res.data.image = res.data.image[0].url;
        }
        callback(res.data);
      }
    })
  }

  scrapeTwitterRequest(params, callback) {
    scrapeTwitterURL(params).then((res) => {
      if (callback) {
        callback(res.data);
      }
    })
  }

  scrapePinterestRequest(url, callback) {
    scrapePinterestURL(url).then((res) => {
      if (callback) {
        callback(res.data);
      }
    })
  }

  scrollTo(key) {
    this.setState({
      activeTab: key,
    }, () => {
      document.querySelector(`#${key}`).scrollIntoView({ behavior: "smooth" });
    })
  }

  componentWillReceiveProps(props) {
    if (!isEqual(props, this.props)) {
      this.setState();
    }
    if (!props.visible) {
      setTimeout(() => {
        this.scrollTo("tab1");
      }, 1250)
    }
  }

  onScroll = debounce((e) => {
    if (document.querySelector('.new-campaign')) {
      let currentTab = "";
      const scrollPosition = e.target.scrollTop;
      const windowHeight = document.querySelector("body").clientHeight;
      const tab1Position = document.querySelector("#tab1").offsetTop;
      const tab2Position = document.querySelector("#tab2").offsetTop;
      const tab3Position = document.querySelector("#tab3").offsetTop;
      const tab4Position = document.querySelector("#tab4").offsetTop;
      const tab5Position = document.querySelector("#tab5").offsetTop;
      if (scrollPosition + windowHeight / 3 > tab1Position) {
        currentTab = "tab1"
      }
      if (scrollPosition + windowHeight / 3 > tab2Position) {
        currentTab = "tab2"
      }
      if (scrollPosition + windowHeight / 3 > tab3Position) {
        currentTab = "tab3"
      }
      if (scrollPosition + windowHeight / 3 > tab4Position) {
        currentTab = "tab4"
      }
      if (scrollPosition + windowHeight / 3 > tab5Position) {
        currentTab = "tab5"
      }
      if (currentTab !== "") {
        this.setState({
          activeTab: currentTab
        })
      }
    }
  }, 50)

  componentDidMount() {
    document.querySelector(".ant-layout-content").style.width = "100%";
    document.querySelector(".ant-layout-content").style.padding = "0 15px";
    const types = {
      "video_to_gif": "AutoplayVideoElement",
      "personalised_video": "PersonalisedVideoElement",
      "messenger_personalised_video": "MessengerPersonalisedVideoElement",
      "yes_no_bar": "YesNoElement",
      "feedback": "FeedbackElement",
      "polls": "PollsElement",
      "qna": "QNAElement",
      "link_preview": "LinkPreviewElement",
      "self_destruct_message": "SelfDestructMessageElement",
      "youtube_embed": "YoutubeEmbedElement",
      "vimeo_embed": "VimeoEmbedElement",
      "googlemap_embed": "MapEmbedElement",
      "instagram_embed": "InstagramEmbedElement",
      "pinterest_embed": "PinterestEmbedElement",
      "image_embed": "ImageEmbedElement",
      "gif_embed": "GIFEmbedElement",
      "social_badges": "SocialBadgesElement",
      "social_share_buttons": "SocialShareElement",
      "cta_button": "CTAElement",
      "timers": "TimerElement",
      "hellobar_timer": "HellobarTimerElement",
      "personalized_images": "PersonalisedImageElement",
      "messenger_personalized_images": "MessengerPersonalisedImageElement",
      "footer_widget": "FooterWidgetElement",
      "signature": "SignatureElement",
      "twitter_tweet": "TwitterEmbedElement",
      "twitter_timeline": "TwitterTimelineElement",
      "group_event_poll": "GroupEventElement"
    }
    newElement(this.props.match.params.domid, this.props.match.params.elementid).then((res) => {
      let newState = {
        loading: false,
        categories: res.data.categories,
        timezones: res.data.timezones,
        wlbldoms: res.data.wlbldoms.filter((w) => w.domain_status === "Pointed")
      };
      if (this.props.auth.plan && this.props.auth.plan.plan_features && this.props.auth.plan.plan_features.CLONE_CAMPAIGN === 1) {
        if (res.data.element) {
          newState.element = res.data.element;
          newState.element.element_name = newState.element.element_name + " Copy";
          delete (newState.element._id);
          newState.elementForm = {
            visible: true,
            type: types[newState.element.element_type]
          }
        }
      }
      this.setState(newState, () => {
        document.querySelector("body").addEventListener("scroll", this.onScroll, true)
      })
    })
  }

  componentWillUnmount() {
    document.querySelector(".ant-layout-content").style.width = "1200px";
    document.querySelector(".ant-layout-content").style.padding = "0";
    document.querySelector("body").removeEventListener("scroll", this.onScroll);
  }

  render() {
    const PageWrapper = (children) => (
      <div className="new-campaign">
        {/* <MainHeadingBar title="New Element" hideCTA /> */}
        {children}
        <ElementFormContainer
          {...this.props}
          plan={this.props.auth.plan}
          element={this.state.element}
          onCancel={() => this.dismissElementForm()}
          toggleLibraryModal={(type, onChoose, tab) => this.toggleLibraryModal(type, onChoose, tab)}
          scrapeURL={(url, cb) => this.scrapeURLRequest(url, cb)}
          scrapeTwitterURL={(url, cb) => this.scrapeTwitterRequest(url, cb)}
          scrapePinterestURL={(url, cb) => this.scrapePinterestRequest(url, cb)}
          type={this.state.elementForm.type}
          visible={this.state.elementForm.visible}
          timezones={this.state.timezones}
          categories={this.state.categories}
          wlbldoms={this.state.wlbldoms} />
        <LibraryModal
          {...this.props}
          tab={this.state.libraryModal.tab}
          onChoose={this.state.libraryModal.onChoose}
          uploadType={this.state.libraryModal.type}
          visible={this.state.libraryModal.visible}
          onCancel={() => this.toggleLibraryModal()} />
      </div>
    )

    if (this.state.loading) {
      return PageWrapper(<Skeleton active />);
    }

    if (!this.state.loading && this.state.error) {
      return PageWrapper(<Alert
        message="Error"
        description={this.state.errorMsg}
        type="error"
        showIcon
      />)
    }

    return PageWrapper(
      <div className="elements-container ant-tabs ant-tabs-left ant-tabs-vertical ant-tabs-line">
        <div role="tablist" className="ant-tabs-bar ant-tabs-left-bar" tabIndex="0">
          <div className="ant-tabs-nav-container">
            <span unselectable="unselectable" className="ant-tabs-tab-prev ant-tabs-tab-btn-disabled">
              <span className="ant-tabs-tab-prev-icon"></span>
            </span>
            <span unselectable="unselectable" className="ant-tabs-tab-next ant-tabs-tab-btn-disabled">
              <span className="ant-tabs-tab-next-icon"></span>
            </span>
            <div className="ant-tabs-nav-wrap">
              <div className="ant-tabs-nav-scroll">
                <h1>New Element</h1>
                <div className="ant-tabs-nav ant-tabs-nav-animated">
                  <div>
                    <div
                      role="tab"
                      aria-disabled="false"
                      aria-selected="true"
                      onClick={() => this.scrollTo("tab1")}
                      className={`ant-tabs-tab ${this.state.activeTab === "tab1" ? 'ant-tabs-tab-active' : ''}`}>Autoplay Videos
                      </div>
                    <div
                      role="tab"
                      aria-disabled="false"
                      aria-selected="false"
                      onClick={() => this.scrollTo("tab2")}
                      className={`ant-tabs-tab ${this.state.activeTab === "tab2" ? 'ant-tabs-tab-active' : ''}`}>Self Interacting Elements
                      </div>
                    <div
                      role="tab"
                      aria-disabled="false"
                      aria-selected="false"
                      onClick={() => this.scrollTo("tab3")}
                      className={`ant-tabs-tab ${this.state.activeTab === "tab3" ? 'ant-tabs-tab-active' : ''}`}>Conversion Elements
                      </div>
                    <div
                      role="tab"
                      aria-disabled="false"
                      aria-selected="false"
                      onClick={() => this.scrollTo("tab4")}
                      className={`ant-tabs-tab ${this.state.activeTab === "tab4" ? 'ant-tabs-tab-active' : ''}`}>Engaging / Growth Elements
                      </div>
                    <div
                      role="tab"
                      aria-disabled="false"
                      aria-selected="false"
                      onClick={() => this.scrollTo("tab5")}
                      className={`ant-tabs-tab ${this.state.activeTab === "tab5" ? 'ant-tabs-tab-active' : ''}`}>Messenger Widgets
                      </div>
                  </div>
                  <div className="ant-tabs-ink-bar ant-tabs-ink-bar-animated">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ant-tabs-content ant-tabs-left-content">
          <div className="tab-body">
            <div>
              <Row id="tab1" gutter={30} style={{ marginBottom: 50 }}>
                <h1>Autoplay Videos</h1>
                <hr />
                <Col xs={8}>
                  <Card className="element-container" onClick={() => this.toggleElementForm("PersonalisedVideoElement")}><img src={PersonalisedAutoplayVideoElement} alt="" /> Personalised Autoplay Video</Card>
                </Col>
                <Col xs={8}>
                  <Card className="element-container" onClick={() => this.toggleElementForm("AutoplayVideoElement")}><img src={AutoplayVideoElement} alt="" /> Autoplay Video with Mute Button</Card>
                </Col>
              </Row>
              <Row id="tab2" gutter={30} style={{ marginBottom: 50 }}>
                <h1>Self Interacting Elements</h1>
                <hr />
                <Col xs={8}>
                  <Card className="element-container" onClick={() => this.toggleElementForm("YesNoElement")}><img src={YesNoElement} alt="" /> Yes / No Bar</Card>
                </Col>
                <Col xs={8}>
                  <Card className="element-container" onClick={() => this.toggleElementForm("FeedbackElement")}><img src={FeedbackElement} alt="" /> Feedback</Card>
                </Col>
                <Col xs={8}>
                  <Card className="element-container" onClick={() => this.toggleElementForm("PollsElement")}><img src={PollsElement} alt="" /> Polls</Card>
                </Col>
                <Col xs={8}>
                  <Card className="element-container" onClick={() => this.toggleElementForm("QNAElement")}><img src={QNAElement} alt="" /> QnA</Card>
                </Col>
                <Col xs={8}>
                  <Card className="element-container" onClick={() => this.toggleElementForm("GroupEventElement")}><img src={GroupEventElement} alt="" /> Group Event Poll</Card>
                </Col>
              </Row>
              <Row id="tab3" gutter={30} style={{ marginBottom: 50 }}>
                <h1>Conversion Elements</h1>
                <hr />
                <Col xs={8}>
                  <Card className="element-container" onClick={() => this.toggleElementForm("TimerElement")}><img src={TimerElement} alt="" /> Timers</Card>
                </Col>
                <Col xs={8}>
                  <Card className="element-container" onClick={() => this.toggleElementForm("HellobarTimerElement")}><img src={HellobarTimerElement} alt="" /> Hellobar Timer</Card>
                </Col>
                <Col xs={8}>
                  <Card className="element-container" onClick={() => this.toggleElementForm("PersonalisedImageElement")}><img src={PersonalisedImageElement} alt="" /> Personalized Images</Card>
                </Col>
                <Col xs={8}>
                  <Card className="element-container" onClick={() => this.toggleElementForm("LinkPreviewElement")}><img src={LinkPreviewElement} alt="" /> Link Preview Cards</Card>
                </Col>
                <Col xs={8}>
                  <Card className="element-container" onClick={() => this.toggleElementForm("CTAElement")}><img src={CTAElement} alt="" /> Call to Action Buttons</Card>
                </Col>
                <Col xs={8}>
                  <Card className="element-container" onClick={() => this.toggleElementForm("FooterWidgetElement")}><img src={FooterWidgetElement} alt="" /> Footer Widgets</Card>
                </Col>
                <Col xs={8}>
                  <Card className="element-container" onClick={() => this.toggleElementForm("SignatureElement")}><img src={SignatureElement} alt="" /> Signatures</Card>
                </Col>
              </Row>
              <Row id="tab4" gutter={30}>
                <h1>Engaging / Growth Elements</h1>
                <hr />
                <Col xs={8}>
                  <Card className="element-container" onClick={() => this.toggleElementForm("SocialBadgesElement")}><img src={SocialBadgesElement} alt="" /> Social Badges</Card>
                </Col>
                <Col xs={8}>
                  <Card className="element-container" onClick={() => this.toggleElementForm("SocialShareElement")}><img src={SocialShareElement} alt="" /> Social Share Buttons</Card>
                </Col>
                <Col xs={8}>
                  <Card className="element-container" onClick={() => this.toggleElementForm("SelfDestructMessageElement")}><img src={SelfDestructMessageElement} alt="" /> Self Destructing Messages</Card>
                </Col>
                <Col xs={8}>
                  <Card className="element-container" onClick={() => this.toggleElementForm("TwitterEmbedElement")}><img src={TwitterEmbedElement} alt="" /> Twitter Tweet Embed</Card>
                </Col>
                <Col xs={8}>
                  <Card className="element-container" onClick={() => this.toggleElementForm("YoutubeEmbedElement")}><img src={YoutubeEmbedElement} alt="" /> Youtube Embed</Card>
                </Col>
                <Col xs={8}>
                  <Card className="element-container" onClick={() => this.toggleElementForm("VimeoEmbedElement")}><img src={VimeoEmbedElement} alt="" /> Vimeo Embed</Card>
                </Col>
                <Col xs={8}>
                  <Card className="element-container" onClick={() => this.toggleElementForm("MapEmbedElement")}><img src={MapEmbedElement} alt="" /> Google Map Embed</Card>
                </Col>
                <Col xs={8}>
                  <Card className="element-container" onClick={() => this.toggleElementForm("InstagramEmbedElement")}><img src={InstagramEmbedElement} alt="" /> Instagram Embed</Card>
                </Col>
                <Col xs={8}>
                  <Card className="element-container" onClick={() => this.toggleElementForm("PinterestEmbedElement")}><img src={PinterestEmbedElement} alt="" /> Pinterest Embed</Card>
                </Col>
                <Col xs={8}>
                  <Card className="element-container" onClick={() => this.toggleElementForm("TwitterTimelineElement")}><img src={TwitterTimelineElement} alt="" /> Twitter Timeline Embed</Card>
                </Col>
                <Col xs={8}>
                  <Card className="element-container" onClick={() => this.toggleElementForm("GIFEmbedElement")}><img src={GIFEmbedElement} alt="" /> GIF Embed</Card>
                </Col>
                <Col xs={8}>
                  <Card className="element-container" onClick={() => this.toggleElementForm("ImageEmbedElement")}><img src={ImageEmbedElement} alt="" /> Image Embed</Card>
                </Col>
              </Row>
              <Row id="tab5" gutter={30} style={{ marginBottom: 250 }}>
                <h1>Messenger Widgets</h1>
                <hr />
                <Col xs={8}>
                  <Card className="element-container" onClick={() => this.toggleElementForm("MessengerPersonalisedVideoElement")}><img src={PersonalisedAutoplayVideoElement} alt="" /> Personalised Autoplay Video</Card>
                </Col>
                <Col xs={8}>
                  <Card className="element-container" onClick={() => this.toggleElementForm("MessengerPersonalisedImageElement")}><img src={PersonalisedImageElement} alt="" /> Personalized Images</Card>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.auth.auth
  }
}

export default connect(mapStateToProps)(NewElement);